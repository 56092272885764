.title-container {
    padding: 24px;
    .title {
        display: inline-block;
        padding-bottom: 15px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            height: 1px;
            background: #222;
            width: 125%;
            left: -12.5%;
            bottom: 0;
            @media(max-width: 500px) {
                width: 100%;
                left: 0;
            }
        }
    }
}

.block {
    background: white;
    padding: 0 15px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);

    .block-header {
        padding-top: 15px;
        color: $main-color;
        font-weight: bold;

        &.alone {
            padding-bottom: 15px;
        }
    }
    .block-content {
        padding: 15px 0;
        h5 {
            color: $main-color;
        }
    }
    .block-footer {
        border-top: solid 1px #e1e1e1;
        padding: 1rem 0;
        overflow: hidden;
    }
}


#confirm-modal {
    width: 340px;
    .modal-content {
        p {
            margin-top: 40px;
        }
    }
    .modal-footer {
        a.btn,
        a.btn-flat {
            float: none;
            padding: 0 1.6rem;
        }
    }
}

.dropdown-content {
    .dropdown-header {
        &:hover {
            background: none;
            cursor: default;
        }
        span {
            color: black;
            i {
                height: inherit;
                line-height: inherit;
                float: left;
                margin: 0 24px 0 0;
                width: 24px;
            }
        }
    }
}

.card {
    &.login-card {
        @media(min-width: 450px) {
            width: 400px;
            margin: auto;
        }
        .card-title {
            color: $main-color;
        }
        .card-action {
            a,
            button {
                font-size: smaller;
            }
            @media(max-width: 450px) {
                a.btn-flat {
                    margin-right: 0 !important;
                    padding: 0 5px;
                }
                button.btn {
                    padding: 0 8px;
                }
            }
        }
    }
    .card-action {
        overflow: hidden;
    }
}

.error {
    background-color: $error-color;
    color: white;
    padding: 15px;
    display: inline-block;
}

.well {
    padding: 15px;
    .remove-well {
        cursor: pointer;
        &:hover {
            i.material-icons {
                font-weight: bold;
            }
        }
    }
}

.collection-delete-row {
    cursor: pointer;

    &:hover {
        i.material-icons {
            font-weight: bold;
        }
    }
}

.primary-background {
    background: $main-color;
    color: $main-color-text;
}

.secondary-background {
    background: $second-color;
    color: $second-color-text;
}

.success-background {
    background: $success-color;
    color: white;
}

.error-background {
    background: $error-color;
    color: white;
}

.required {
    position: relative;
    &:before {
        position: absolute;
        content: "*";
        color: red;
        right: -10px;
        top: -3px;
    }
}

.relative {
    position: relative;
}

.block-table-responsive-vertical {
    @media screen and (max-width: 768px) {
        background: none;
        box-shadow: unset;
        padding: 0;
        .block-header,
        .block-footer {
            background: white;
            padding: 15px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
        }
        .block-footer {
            border-top: 0;
        }
    }
}

.table-responsive-vertical {
    @media screen and (max-width: 768px) {
        // Tighten up spacing
        & > .table {
            margin-bottom: 0;
            background-color: transparent;
            >thead,
            >tfoot {
                display: none;
            }
            >tbody {
                display: block;
                >tr {
                    display: block;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
                    border-radius: 2px;
                    margin-bottom: $table-cell-padding;
                    >td {
                        background-color: $table-bg;
                        display: block;
                        vertical-align: middle;
                        text-align: right;
                        padding: 15px;
                    }
                    >td[data-title]:before {
                        content: attr(data-title);
                        float: left;
                        font-size: inherit;
                        font-weight: $table-header-font-weight;
                        color: $table-header-font-color;
                    }
                }
            }
        } // Special overrides for the bordered tables
        &>.table-bordered {
            border: 0; // Nuke the appropriate borders so that the parent can handle them
            >tbody {
                >tr {
                    >td {
                        border: 0;
                        border-bottom: 1px solid $table-border-color;
                    }
                    >td:last-child {
                        border-bottom: 0;
                    }
                }
            }
        } // Special overrides for the striped tables
        &>.table-striped {
            >tbody>tr>td,
            >tbody>tr:nth-child(odd) {
                background-color: $table-bg;
            }
            >tbody>tr>td:nth-child(odd) {
                background-color: $table-bg-accent;
            }
        } // Special overrides for hover tables
        &>.table-hover {
            >tbody {
                >tr:hover>td,
                >tr:hover {
                    background-color: $table-bg;
                }
                >tr>td:hover {
                    background-color: $table-bg-hover;
                }
            }
        }
    }
}