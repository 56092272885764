body.admin {
    header,
    main,
    footer {
        @media(min-width: 992px) {
            padding-left: 300px;
        }
    }
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    color: #222;
    background: #f1f1f1;
}

main {
    flex: 1 0 auto;
}

#navbar {
    .brand-logo {
        padding-left: 15px;
        i.material-icons {
            font-size: 2.1rem;
        }
    }
    nav {
        @media(max-width: 400px) {
            .button-collapse {
                margin: 0;
            }
            ul {
                a {
                    font-size: 0.9rem;
                }
            }
        }
    }
}

.side-nav {
    .userView {
        background: rgba(0, 0, 0, 0.5);
    }
    li.active {
        border-left: solid 6px $second-color;
    }
    li {
        div.sidenav-title {
            //color: rgba(0,0,0,.87);
            font-size: 14px;
            font-weight: 500;
            height: 48px;
            line-height: 48px;
            padding: 0 32px;
            margin-top: -8px;
            background: $main-color;
            color: $main-color-text;
            i.material-icons {
                float: left;
                height: 48px;
                line-height: 48px;
                margin: 0 16px 0 0;
                width: 24px; //color: rgba(0, 0, 0, .54);
            }
        }
    }
}

#content {
    padding: 24px;
    @media(max-width: 500px) {
        padding: 15px 5px;
    }
}

.modal {
    &#admin-modal {
        @media(min-width: 420px) {
            width: 400px;
        }
        @media(max-width: 450px) {
            .modal-header {
                padding: 15px;
                h5 {
                    font-size: 1rem;
                    margin: 0;
                }
            }
            .modal-content {
                padding: 15px 15px 0;
                .row {
                    margin-bottom: 10px;
                }
            }
            .modal-footer {
                padding: 0 15px;
                a.btn,
                a.btn-flat {
                    font-size: 12px;
                    padding: 0 7px;
                }
            }
        }
    }
    .modal-header {
        padding: 24px;
        background: $main-color;
    }
}

table.person-infos {

    margin-bottom: 15px;

    tr {
        td:first-child {
            width: 30%;
            color: $main-color;
            font-weight: bold;
            text-align: right;
        }
        td:last-child {
            width: 70%;
            padding-left: 40px;
        }
    }
}

.file-field {
    +label {
        display: none;
    }
}

.collection {
    .collection-item {
        &.active-item {
            color: $second-color;
            font-weight: bold;
        }
        &.done {
            color: $primary-color;
            font-weight: bold;
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: white;
}

::-webkit-scrollbar-thumb {
    background-color: $main-color;
    border: solid 1px scale-color($main-color, $lightness: -10%);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: scale-color($main-color, $lightness: 30%);
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: #000000;
}

::selection {
    background: $main-color;
    color: $main-color-text;
}