$roboto-font-path: "../../assets/vendor/materialize/fonts/roboto/";
$fa-font-path: "../../assets/vendor/font-awesome/fonts";
//$input-focus-color: $main-color;

@import '../vendor/materialize/sass/components/color';

$primary-color: color($mat-color-1-name, $mat-color-1-type);
$secondary-color: color($mat-color-2-name, $mat-color-2-type);

$link-color: $second-color;
