/*****************************/
/*          colors           */
/*****************************/

$main-color: #2196F3;
$main-color-text: #fff;

$second-color: #FF5722;
$second-color-text: #fff;

$error-color: #c62828;
$success-color: #43a047;

$mat-color-1-name: 'blue';
$mat-color-1-type: 'base';
$mat-color-2-name: 'deep-orange';
$mat-color-2-type: 'base';

/*****************************/
/* vertical table responsive */
/*****************************/

$table-header-font-weight : bold;
$table-header-font-color : $main-color;
$table-cell-padding : 1.6rem;
$table-condensed-cell-padding : $table-cell-padding/2;
$table-bg : #fff;
$table-bg-accent : #f5f5f5;
$table-bg-hover : rgba(0, 0, 0, .12);
$table-bg-active : $table-bg-hover;
$table-border-color : #e0e0e0;