// @media #{$extra-large-and-up} {
//     nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
//         display: none;
//     }
// }

// @media #{$large-and-up} {
//     nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
//         display: block;
//     }
// }